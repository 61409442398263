/* RESPONSIBLE TEAM: team-frontend-tech */
import Service from '@ember/service';
import Workspace, { type WorkspaceForList } from '../objects/workspace';
import { action } from '@ember/object';
import { get, put } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import AdminWithPermissions, {
  type AdminWithPermissionsWireFormat,
  AdminPermissions,
} from 'embercom/objects/inbox/admin-with-permissions';
import AdminSummary from 'embercom/objects/inbox/admin-summary';
import { type ValidLocales } from '../components/inbox2/user-menu/locale-switcher';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import storage from 'embercom/vendor/intercom/storage';
import generateUUID from 'embercom/lib/uuid-generator';
import type RegionService from 'embercom/services/region-service';
import { type default as IntlService, DEFAULT_LOCALE } from 'embercom/services/intl';
import type Store from '@ember-data/store';
import type CopilotApi from 'embercom/services/copilot-api';
import Theme from 'embercom/objects/inbox/types/theme';

const DEFAULT_THEME = Theme.System;

export default class Session extends Service {
  @tracked private _workspace?: Workspace;
  @tracked private _teammate?: AdminWithPermissions;
  @tracked private _workspacesList?: Array<WorkspaceForList>;
  id: string;

  @service declare intl: IntlService;
  @service modelDataCacheService: any;
  @service declare store: Store;
  @service intercomEventService: any;
  @service declare regionService: RegionService;
  @service declare copilotApi: CopilotApi;

  @tracked theme?: string;
  media = window.matchMedia('(prefers-color-scheme: dark)');

  // for <Inbox2::Adapters::Inbox1 />
  @tracked inbox1Status: 'unknown' | 'booting' | 'booted' = 'unknown';

  constructor() {
    super(...arguments);

    this.id = generateUUID();
  }

  get isWorkspaceLoaded(): boolean {
    return !!this._workspace;
  }

  get isTeammateLoaded(): boolean {
    return !!this._teammate;
  }

  get isWorkspacesListLoaded(): boolean {
    return !!this._workspacesList;
  }

  get workspace(): Workspace {
    if (!this._workspace) {
      throw new Error(
        'Attempted to access the workspace before it is set. Please call session.switchWorkspace first.',
      );
    }

    return this._workspace;
  }

  get teammate(): AdminWithPermissions {
    if (!this._teammate) {
      throw new Error(
        `Attempted to access the teammate before it is set. Please call session.getTeammate first.`,
      );
    }
    return this._teammate;
  }

  get workspacesList(): Array<WorkspaceForList> {
    if (!this._workspacesList) {
      throw new Error(
        `Attempted to access the workspacesList before it is set. Please call session.getWorkspaces first.`,
      );
    }
    return this._workspacesList;
  }

  get isCopilotEnabled() {
    return this.copilotApi.isCopilotEnabled;
  }

  get hasOptedInForProductIa() {
    return (
      this._workspace?.isFeatureEnabled('team-product-exploration-product-ia') ||
      this._teammate?.optedIntoProductIA
    );
  }

  switchWorkspace(id: string): void {
    if (this._workspace?.id === id) {
      return;
    }
    this._workspace = new Workspace(id);
  }

  async getWorkspaces() {
    this._workspacesList = (await get(`/ember/admins/apps.json`)) as Array<WorkspaceForList>;
  }

  async getTeammate(workspaceId: string) {
    let data: AdminWithPermissionsWireFormat = await get(
      `/ember/admins/mini_me.json?app_id=${workspaceId}`,
    );

    this._teammate = AdminWithPermissions.deserialize(data);
  }

  async updateTeammate(params: Record<string, unknown>) {
    let data = {
      app_id: this.workspace.id,
      id: this.teammate.id,
      ...params,
    };
    return await put(`/ember/admins/${this.teammate.id}`, data);
  }

  // allows mocking intershop access in tests
  mockIntershopAccess() {
    if (ENV.environment !== 'test') {
      throw new Error(`You cannot change workspace attributes outside of tests`);
    }

    this.workspace.hasIntershopAccess = true;
  }

  directlySetTeammate(teammate: AdminWithPermissions | AdminSummary) {
    if (ENV.environment !== 'test') {
      throw new Error(`You cannot directly set the teammate outside of tests`);
    }

    // allow using an AdminSummary in tests
    if (teammate instanceof AdminWithPermissions) {
      this._teammate = teammate;
    } else if (teammate instanceof AdminSummary) {
      this._teammate = new AdminWithPermissions(
        teammate.id,
        teammate.name,
        teammate.imageURL,
        new AdminPermissions({
          conversationAccess: {},
          hasInboxAccess: true,
          canManageTeammates: true,
          canChangeAwayMode: true,
          canManageTags: true,
          canManageMacros: true,
          hasFullAccess: true,
          canInboxConversationTranscriptsExport: true,
          canManageInboxViews: true,
          canAccessRealTimeDashboard: true,
          canReassignConversations: true,
          canRedactConversationParts: true,
          canSendFromCustomAddresses: true,
          canReplyToInboundConversations: true,
          canAccessWorkspaceSettings: true,
          canAccessContacts: true,
          canAccessBillingSettings: true,
          canAccessAllConversations: true,
          canListenAndBargeOnCalls: true,
          canManageKnowledgeBaseContent: true,
        }),
        [],
        [],
        DEFAULT_LOCALE,
        false,
        false,
      );
    }
  }

  setupTheme() {
    let themeFromLocalStorage = storage.get(this.darkModeSettingKey);

    // handle legacy theme setting where false means light and true means dark
    if (typeof themeFromLocalStorage === 'boolean') {
      this.theme = themeFromLocalStorage ? Theme.Dark : Theme.Light;
      this.persistTheme();
    } else {
      this.theme =
        Object.values(Theme).find((value) => value === themeFromLocalStorage) ?? DEFAULT_THEME;
    }

    this.media.addEventListener('change', this.updateUIWithTheme);

    this.updateUIWithTheme();
  }

  @action
  teardownTheme() {
    this.media.removeEventListener('change', this.updateUIWithTheme);
    this.theme = undefined;
    document.documentElement.classList.remove('dark');
  }

  @action
  private updateUIWithTheme() {
    if (this.darkModeEnabled) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }

  private persistTheme() {
    storage.set(this.darkModeSettingKey, this.theme);
  }

  @action
  setTheme(theme: Theme) {
    this.theme = theme;
    this.persistTheme();
    this.updateUIWithTheme();
  }

  get darkModeEnabled(): boolean {
    let theme = this.theme ?? DEFAULT_THEME;
    if (theme === Theme.System) {
      theme = this.media.matches ? Theme.Dark : Theme.Light;
    }
    return theme === Theme.Dark;
  }

  get isThemeLoaded(): boolean {
    return this.theme !== undefined;
  }

  get locale() {
    return this.intl.primaryLocale;
  }

  @action async changeLanguage(
    newLocale: ValidLocales,
    app = this.workspace as any,
    admin = this.teammate as any,
  ) {
    let data = {
      app_id: app.id,
      id: admin.id,
      locale: newLocale,
    };
    await put(`/ember/admins/update_locale`, data);
    await this.intl.switchLocale(newLocale);
    if (this.isInbox2Booted) {
      admin.locale = newLocale;
      this.modelDataCacheService.clear();
    } else {
      this.store.pushPayload({ admin: { id: admin.id, locale: newLocale } });
      admin.updateLocalCache(this.modelDataCacheService);
      app.updateLocalCache(this.modelDataCacheService);
    }
    this.trackChangeLocale(newLocale, this.isInbox2Booted ? 2 : 1);
  }

  get darkModeSettingKey() {
    return `inbox-dark-mode-${this.workspace.id}`;
  }

  get showLightInbox() {
    return this.teammate.hasVbp2CollaboratorSeat || this.teammate.hasPricing5LiteSeat;
  }

  get canAccessCallingBeta() {
    return this.regionService.isUS;
  }

  get skipCanReplyToInboundCheck() {
    return (
      this.teammate.permissions.canReplyToInboundConversations ||
      !this.workspace.isFeatureEnabled('multiple_seat_types')
    );
  }

  private get isInbox2Booted() {
    return !!this._workspace;
  }

  private trackChangeLocale(newLocale: ValidLocales, inboxVersion: 1 | 2) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'switched',
      object: 'language',
      section: 'admin_details',
      language: newLocale,
      inbox_version: inboxVersion,
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    session: Session;
  }
}
