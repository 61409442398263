/* RESPONSIBLE TEAM: team-reporting */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type Report from 'embercom/models/reporting/custom/report';
import type Admin from 'embercom/models/admin';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { without, indexBy, omit } from 'underscore';
import { type TaskGenerator } from 'ember-concurrency';
import { cached } from 'tracked-toolbox';
import { taskFor } from 'ember-concurrency-ts';

export interface IntercomAvatar {
  isCompany: boolean;
  color: string;
}
export interface IntercomAdmin {
  id?: string;
  name: string;
  avatarData: IntercomAvatar;
}
export interface ViewItem {
  id: string;
  name: string;
  description?: string;
  updatedAt?: Date | null;
  route: string;
  isStaticReport: boolean;
  author?: Admin | IntercomAdmin | null | undefined;
  isNew?: boolean;
}

export interface FolderReport {
  title: string;
  id: string;
}

export interface ReportItem {
  id: string;
  name: string;
  route?: string;
  isStaticReport?: boolean;
}

export interface ReportingView {
  id: string;
  name: string; // translation key
  icon?: string;
  iconSet?: string;
  svgIcon?: string;
  reports: ViewItem[];
}

const topicReportIds = ['topics', 'suggestions'];

export default class ReportingViews extends Service {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare session: Session;
  @service declare appService: any;
  @service declare permissionsService: any;
  @tracked reports: Report[] = [];

  @tracked private isSetup = false;

  @task({ drop: true })
  *refreshReports(): TaskGenerator<void> {
    let allReports = yield this.store.findAll('reporting/custom/report', {
      reload: true,
      backgroundReload: false,
    });
    this.reports = allReports.filter((report: Report) => !report.isStaticReport);
  }

  @task({ drop: true })
  *setUp(): TaskGenerator<void> {
    if (!this.isSetup) {
      yield taskFor(this.refreshReports).perform();
      this.isSetup = true;
    }
  }

  shutDown() {
    this.isSetup = false;
  }

  findView(id: string): ReportingView | undefined {
    return this.dynamicViews.find((view) => view.id === id);
  }

  get canViewCustomReports() {
    if (this.appService.app.canShareReportsInternally) {
      return this.permissionsService.currentAdminCan('can_access_reporting');
    }
    return this.permissionsService.currentAdminCan('can_reporting__custom_reports__read');
  }

  @cached
  get allReports(): ViewItem[] {
    let dynamicReports = this.reports
      .sortBy('updatedAt')
      .reverse()
      .map((report) => {
        return {
          id: report.id,
          name: report.title || this.intl.t('reporting.custom-reports.report.untitled'),
          route: 'apps.app.reports.custom-reports.report.show',
          isStaticReport: false,
          updatedAt: report.updatedAt,
          author: report.createdByAdmin,
          isNew: report.get('isNew'),
        };
      });

    let staticReportNames: Set<string> = new Set(Object.keys(this.staticReports));

    let staticReports = Object.entries(this.staticReports)
      .filter(([k, _]) => staticReportNames.has(k))
      .map(([id, report]) => {
        return {
          id,
          name: report.name,
          route: report.route,
          isStaticReport: true,
          updatedAt: null,
          author: {
            name: this.intl.t('reporting.intercom-report-author'),
            avatarData: {
              isCompany: true,
              color: '#9401b2',
            },
          },
          isNew: false,
        };
      });

    return [...staticReports, ...dynamicReports];
  }

  get allPersistedReportsWithoutTopics(): ViewItem[] {
    return this.allReports.filter((report) => !report.isNew && !topicReportIds.includes(report.id));
  }

  @cached
  get allReportsById(): Record<string, ViewItem> {
    return indexBy(this.allReports, 'id');
  }

  get myReports(): ViewItem[] {
    return this.allPersistedReportsWithoutTopics.filter(
      (report) => report.author?.id === this.appService.app.currentAdmin.id,
    );
  }

  get dynamicViews(): ReportingView[] {
    return [
      {
        id: 'all-reports',
        name: 'reporting.submenu-component.items.all-reports',
        icon: 'bar-charts',
        iconSet: 'standard',
        reports: this.allPersistedReportsWithoutTopics,
      },
      {
        id: 'my-reports',
        name: 'reporting.submenu-component.items.my-reports',
        icon: 'bar-charts',
        iconSet: 'standard',
        reports: this.myReports,
      },
    ];
  }

  get humanSupportReports() {
    let allHumanSupportReports: string[] = [
      'customer-support-overview',
      'calls',
      'conversations',
      'conversation-tags',
      'customer-satisfaction',
      'effectiveness',
      ...(this.appService.app.hasCopilotReporting ? ['copilot'] : []),
      'responsiveness',
      'slas',
      'team-inbox-performance-report',
      'teammate-performance-report',
      'tickets',
    ];

    let reportsToRemove = [this.appService.app.canUseInboxSlas ? '' : 'slas'];

    return without(allHumanSupportReports, ...reportsToRemove);
  }

  get staticViews(): ReportingView[] {
    return [
      {
        id: 'automation',
        name: 'reporting.submenu-component.views.automation',
        icon: 'bounced',
        reports: [
          'conversational-support-funnel',
          'fin',
          'resolution-bot',
          'customer-support-custom-bots',
        ]
          .map((id) => this.staticReports[id])
          .compact(),
      },
      {
        id: 'human-support',
        name: 'reporting.submenu-component.views.human-support',
        reports: this.humanSupportReports.map((id) => this.staticReports[id]).compact(),
      },
      {
        id: 'proactive',
        name: 'reporting.submenu-component.views.proactive',
        reports: ['articles'].map((id) => this.staticReports[id]),
      },
      {
        id: 'conversation-topics',
        name: 'reporting.submenu-component.items.conversational-insights.title',
        reports: this.topicReports,
      },
    ];
  }

  get topicReports(): ViewItem[] {
    return topicReportIds.map((id) => this.staticReports[id]);
  }

  get finReportTitle(): string {
    return this.intl.t(`reporting.submenu-component.items.customer-support.ai-chatbot`);
  }

  get staticReports(): Record<string, ViewItem> {
    let reports = [
      {
        id: 'overview',
        name: this.intl.t(
          'reporting.submenu-component.items.customer-support.intercom-reports-overview',
        ),
        route: 'apps.app.reports.overview',
      },
      {
        id: 'articles',
        name: this.intl.t('reporting.submenu-component.items.customer-support.articles'),
        route: 'apps.app.reports.customer-support.articles.index',
      },
      {
        id: 'calls',
        name: this.intl.t('reporting.submenu-component.items.calls'),
        route: 'apps.app.reports.customer-support.calls',
      },
      this.appService.app.hasCopilotReporting
        ? {
            id: 'copilot',
            name: this.intl.t('reporting.submenu-component.items.customer-support.copilot'),
            route: 'apps.app.reports.customer-support.fin-ai-copilot',
          }
        : null,
      {
        id: 'conversations',
        name: this.intl.t('reporting.submenu-component.items.customer-support.conversations'),
        route: 'apps.app.reports.customer-support.conversations-flexible',
      },
      {
        id: 'conversational-support-funnel',
        name: this.intl.t(
          'reporting.submenu-component.items.customer-support.conversational-support',
        ),
        route: 'apps.app.reports.customer-support.conversational-support-funnel',
      },
      {
        id: 'conversation-tags',
        name: this.intl.t('reporting.submenu-component.items.customer-support.conversation-tags'),
        route: 'apps.app.reports.customer-support.conversation-tags',
      },
      {
        id: 'customer-support-overview',
        name: this.intl.t('reporting.submenu-component.items.customer-support.title-overview'),
        route: 'apps.app.reports.customer-support.overview',
      },
      {
        id: 'customer-satisfaction',
        name: this.intl.t('reporting.submenu-component.items.customer-satisfaction'),
        route: this.csatRoute,
      },
      {
        id: 'customer-engagement-overview',
        name: this.intl.t('reporting.submenu-component.items.customer-engagement.title-overview'),
        route: 'apps.app.reports.outbound-engagement.overview',
      },
      {
        id: 'customer-support-custom-bots',
        name: this.intl.t('reporting.submenu-component.items.customer-support.custom-bots'),
        route: 'apps.app.reports.customer-support.custom-bots.sent',
      },
      {
        id: 'effectiveness',
        name: this.intl.t('reporting.submenu-component.items.customer-support.effectiveness'),
        route: 'apps.app.reports.customer-support.effectiveness',
      },
      {
        id: 'email-deliverability',
        name: this.intl.t(
          'reporting.submenu-component.items.customer-engagement.email-deliverability',
        ),
        route: 'apps.app.reports.outbound-engagement.email-deliverability',
      },
      {
        id: 'fin',
        name: this.finReportTitle,
        route: 'apps.app.reports.customer-support.fin-ai-agent',
      },
      {
        id: 'lead-generation',
        name: this.intl.t('reporting.submenu-component.items.lead-generation.leads'),
        route: 'apps.app.reports.lead-generation.leads',
      },
      {
        id: 'lead-generation-custom-bots',
        name: this.intl.t(
          'reporting.submenu-component.items.lead-generation.custom-bots-lead-generation',
        ),
        route: 'apps.app.reports.lead-generation.custom-bots',
      },
      {
        id: 'responsiveness',
        name: this.intl.t('reporting.submenu-component.items.responsiveness'),
        route: 'apps.app.reports.customer-support.responsiveness',
      },
      {
        id: 'sales',
        name: this.intl.t('reporting.submenu-component.items.lead-generation.sales'),
        route: 'apps.app.reports.lead-generation.sales',
      },
      this.appService.app.canUseInboxSlas
        ? {
            id: 'slas',
            name: this.intl.t('reporting.submenu-component.items.customer-support.slas'),
            route: 'apps.app.reports.customer-support.slas',
          }
        : null,
      {
        id: 'team-inbox-performance-report',
        name: this.intl.t(
          'reporting.submenu-component.items.customer-support.team-inbox-performance',
        ),
        route: 'apps.app.reports.customer-support.team-inbox-performance-report',
      },
      {
        id: 'teammate-performance-report',
        name: this.intl.t(
          'reporting.submenu-component.items.customer-support.teammate-performance',
        ),
        route: 'apps.app.reports.customer-support.teammate-performance-report',
      },
      {
        id: 'tickets',
        name: this.intl.t('reporting.submenu-component.items.tickets'),
        route: 'apps.app.reports.customer-support.tickets',
      },
      {
        name: this.intl.t('reporting.submenu-component.items.conversational-insights.topics'),
        route: 'apps.app.reports.conversational-insights.topics',
        id: 'topics',
      },
      {
        name: this.intl.t('reporting.submenu-component.items.conversational-insights.suggestions'),
        route: 'apps.app.reports.conversational-insights.suggested-topics',
        id: 'suggestions',
      },
    ]
      .compact()
      .map((report) => {
        return { ...report, isStaticReport: true };
      });
    return indexBy(reports, 'id');
  }

  get csatRoute(): string {
    return this.appService.app.canUseCsatV2
      ? 'apps.app.reports.customer-support.customer-satisfaction-v2'
      : 'apps.app.reports.customer-support.customer-satisfaction';
  }

  get staticReportsWithoutTopics(): Record<string, ViewItem> {
    return omit(this.staticReports, topicReportIds);
  }
}

declare module '@ember/service' {
  interface Registry {
    reportingViews: ReportingViews;
    'reporting-views': ReportingViews;
  }
}
