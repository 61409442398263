/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { readOnly } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { isEmpty, isNone } from '@ember/utils';
import extractPermissionKeySections from 'embercom/lib/settings/permissions/extract-permission-key-sections';
import toBackendPermissionKey from 'embercom/lib/settings/permissions/to-backend-permission-key';
import { Promise as EmberPromise, resolve } from 'rsvp';

export const PERMISSION_TO_ROUTE_MAPPINGS = {
  can_manage_teammates: 'apps.app.settings.teammates.index',
  can_manage_teams: 'apps.app.settings.helpdesk.teams',
  can_manage_tags: 'apps.app.settings.data.tags',
  can_manage_messages_settings: 'apps.app.settings.proactive-support.customization',
  can_access_developer_hub: 'apps.app.settings.app-settings.legacy-webhooks',
};

const PERMISSION_TO_GENERAL_SETTINGS_ROUTE_MAPPINGS = {
  can_access_workspace_settings: 'apps.app.settings.workspace.general',
  can_manage_teammates: 'apps.app.settings.teammates',
  can_manage_teams: 'apps.app.settings.helpdesk.teams',
};

const PERMISSION_TO_INBOX_SETTINGS_ROUTE_MAPPINGS = {
  can_manage_workload_management: 'apps.app.settings.workload-management',
  can_manage_inbox_rules: 'apps.app.settings.inbox-rules.list',
};

let permissionsService = Service.extend({
  appService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  permissionsHierarchyService: service(),
  app: readOnly('appService.app'),
  currentAdminCan(permission) {
    // For some reason the currentAdmin on the app is not a fully fledged admin at sign-up and is missing current_app_id
    // looking up the admins by currentAdmin.id to get the full admin.
    let admin = this.app.admins.findBy('id', this.app.currentAdmin.id);
    let currentAppPermissions = admin.permissions.findBy('app_id', this.app.id);
    // the currentAppPermission returned is not an ember class at sign-up which is why .get() is not used to check the permission
    return currentAppPermissions && currentAppPermissions[permission];
  },
  checkPermission(permissionRequired, message = '') {
    if (isNone(permissionRequired)) {
      throw new Error(`Attempted to check for a blank permission`);
    }

    return new EmberPromise((resolve, reject) => {
      if (this.currentAdminCan(permissionRequired)) {
        resolve();
      } else {
        this.loadAllAdminsAndShowPermissionRequestModal(permissionRequired);
        if (message) {
          reject({ message });
        } else {
          reject();
        }
      }
    });
  },
  async featureFlagForHierarchyPermission(permissionKey) {
    await this.permissionsHierarchyService.ensureDataIsLoaded.perform();
    let backendPermissionKey = toBackendPermissionKey(permissionKey);
    let permissionFromHierarchy =
      this.permissionsHierarchyService.permissionsHierarchy[backendPermissionKey];
    if (isEmpty(permissionFromHierarchy)) {
      let [backendPermissionKeyWithoutAction] = extractPermissionKeySections(permissionKey);
      permissionFromHierarchy =
        this.permissionsHierarchyService.permissionsHierarchy[backendPermissionKeyWithoutAction];
    }
    return permissionFromHierarchy['feature_flag'];
  },
  async checkHierarchyPermissionWithFeatureFlag(permissionRequired) {
    let featureFlag = await this.featureFlagForHierarchyPermission(permissionRequired);
    if (this.appService.app.canUseFeature(featureFlag)) {
      return this.checkPermission(permissionRequired);
    } else {
      return true;
    }
  },
  loadAllAdminsAndShowPermissionRequestModal(permissionRequired) {
    return this.app.fetchAndUpdateAllAdminPermissions().then(() => {
      let adminIds = this.app.adminsWhoCanManageTeammates
        .filter((admin) => admin.id !== this.app.currentAdmin.id)
        .map((admin) => admin.id);
      this.showPermissionModal(adminIds, permissionRequired);
    });
  },
  loadAllAdminsAndShowPermissionRequestModalForMultiplePermissions(
    permissionsRequired = [],
    headerText,
  ) {
    return this.app.fetchAndUpdateAllAdminPermissions().then(() => {
      let adminIds = this.app.adminsWhoCanManageTeammates
        .filter((admin) => admin.id !== this.app.currentAdmin.id)
        .map((admin) => admin.id);
      this.showPermissionModalForMultiplePermissions(adminIds, permissionsRequired, headerText);
    });
  },
  async _cancelTransition(transition, failureRoute) {
    if (!transition.from || transition.from.name === 'application_loading') {
      await transition.router
        .transitionTo(failureRoute || 'apps', {
          queryParams: { forcePlatform: true },
        })
        .followRedirects();
    } else {
      transition.abort();
    }
  },
  async ensurePermissionWhenTransitioning(permissionRequired, transition, failureRoute) {
    if (!this.currentAdminCan(permissionRequired)) {
      this.cancelTransitionAndShowPermissionModal(permissionRequired, transition, failureRoute);
    }
  },
  async cancelTransitionAndShowPermissionModal(permissionRequired, transition, failureRoute) {
    try {
      // Wait for all redirects to complete, if any, before showing the permission modal
      await this._cancelTransition(transition, failureRoute);
    } finally {
      this.loadAllAdminsAndShowPermissionRequestModal(permissionRequired);
    }
  },
  async ensureMultiplePermissionsWhenTransitioning(
    permissionsRequired,
    transition,
    failureRoute,
    headerText,
  ) {
    let allPermissions = permissionsRequired.map((permission) => this.currentAdminCan(permission));
    if (allPermissions.some(Boolean)) {
      return;
    }
    this.cancelTransistionAndShowPermissionModalForMultiplePermissions(
      permissionsRequired,
      transition,
      failureRoute,
      headerText,
    );
  },
  async cancelTransistionAndShowPermissionModalForMultiplePermissions(
    permissionsRequired,
    transition,
    failureRoute,
    headerText,
  ) {
    try {
      // Wait for all redirects to complete, if any, before showing the permission modal
      await this._cancelTransition(transition, failureRoute);
    } finally {
      this.loadAllAdminsAndShowPermissionRequestModalForMultiplePermissions(
        permissionsRequired,
        headerText,
      );
    }
  },
  async ensureHierarchyPermissionWhenTransitioningWithFeatureFlag(
    permissionRequired,
    transition,
    failureRoute,
  ) {
    let featureFlag = await this.featureFlagForHierarchyPermission(permissionRequired);
    if (this.appService.app.canUseFeature(featureFlag)) {
      this.ensurePermissionWhenTransitioning(permissionRequired, transition, failureRoute);
    }
  },
  checkOneOnOneMessagingPermission(permissionRequired, isCompany, selectedCount) {
    if (selectedCount <= 1 && !isCompany) {
      return resolve();
    } else {
      return this.checkPermission(permissionRequired);
    }
  },
  showPermissionModal(adminIds, permissionRequired) {
    let adminsWithPermission = this.app.admins.filter(function (admin) {
      return adminIds.indexOf(admin.id) > -1;
    });
    return new EmberPromise((resolve) => {
      this.modalService.openModal('settings/modals/permission-request', {
        adminsWhoCanChangePermissions: adminsWithPermission,
        permissionRequired,
        complete: resolve,
      });
    });
  },
  showPermissionModalForMultiplePermissions(adminIds, permissionsRequired, headerText) {
    let adminsWithPermission = this.app.admins.filter(function (admin) {
      return adminIds.indexOf(admin.id) > -1;
    });
    return new EmberPromise((resolve) => {
      this.modalService.openModal('settings/modals/permission-request', {
        adminsWhoCanChangePermissions: adminsWithPermission,
        permissionsRequired,
        complete: resolve,
        headerText,
      });
    });
  },
  _findFirstAccessibleRoute(permissionRouteMappings) {
    let permissions = Object.keys(permissionRouteMappings);
    let routeKey = permissions.find((permission) => this.currentAdminCan(permission));

    return permissionRouteMappings[routeKey];
  },
  getFirstAccessibleGeneralSettingsRoute() {
    return this._findFirstAccessibleRoute(PERMISSION_TO_GENERAL_SETTINGS_ROUTE_MAPPINGS);
  },
  getFirstAccessibleSettingsRoute() {
    return this._findFirstAccessibleRoute(PERMISSION_TO_ROUTE_MAPPINGS);
  },
  getFirstAccessibleInboxSettingsRoute() {
    return this._findFirstAccessibleRoute(PERMISSION_TO_INBOX_SETTINGS_ROUTE_MAPPINGS);
  },
});

export default permissionsService;
