/* RESPONSIBLE TEAM: team-tickets-1 */
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
import Service from '@ember/service';

export default class ConditionalAttributesService extends Service {
  attributeDescriptors: ConversationAttributeDescriptor[] = [];
  attributeValues: Record<string, ConversationAttributeSummary> = {};
  listOptions: Record<string, string[]> = {};
  private updateAttribute?: (attribute: ConversationAttributeSummary, unset?: boolean) => void =
    () => {};

  initialize(
    attributeDescriptors: ConversationAttributeDescriptor[],
    attributeValues: Record<string, ConversationAttributeSummary>,
    updateAttribute?: (attribute: ConversationAttributeSummary, unset?: boolean) => void,
  ) {
    this.attributeDescriptors = attributeDescriptors;
    this.attributeValues = attributeValues;
    this.updateAttribute = updateAttribute;
    if (attributeDescriptors) {
      attributeDescriptors.forEach(this.updateConditionalOptions.bind(this));
    }

    return this;
  }

  updateVisibility(attribute?: ConversationAttributeSummary): ConversationAttributeDescriptor[] {
    if (attribute) {
      return this.evaluateConditionsOnUpdate(this.getAttributeDescriptor(attribute));
    }

    return this.visibleAttributes();
  }

  visibleAttributes(): ConversationAttributeDescriptor[] {
    return this.attributeDescriptors?.filter(this.isAttributeVisible.bind(this));
  }

  recalculateDependentAttributesOptions(attribute: ConversationAttributeSummary) {
    let dependentAttributes = this.getDependentAttributes(this.getAttributeDescriptor(attribute));
    dependentAttributes.filter(this.hasListOptions.bind(this)).forEach((dependentAttribute) => {
      this.updateConditionalOptions(dependentAttribute);
    });
  }

  private isAttributeVisible(descriptor: ConversationAttributeDescriptor): boolean {
    return (
      this.hasNoConditions(descriptor) ||
      Boolean(this.attributeValues[descriptor.id]?.value) ||
      this.anyConditionsMet(descriptor)
    );
  }

  private hasNoConditions(descriptor: ConversationAttributeDescriptor): boolean {
    return descriptor.conditions.length === 0;
  }

  private hasListOptions(descriptor: ConversationAttributeDescriptor): boolean {
    return Boolean(descriptor.listOptions) && descriptor.listOptions!.length > 0;
  }

  private anyConditionsMet(descriptor: ConversationAttributeDescriptor): boolean {
    return descriptor.conditions.some(this.isConditionMet.bind(this));
  }

  private isConditionMet(condition: any): boolean {
    return (
      this.attributeValues[condition.controllingDescriptorId]?.value ===
      condition.controllingListOptionId
    );
  }

  private evaluateConditionsOnUpdate(
    attribute: ConversationAttributeDescriptor,
  ): ConversationAttributeDescriptor[] {
    let visibleAttributes = this.visibleAttributes();
    this.processControllingAttribute(attribute, visibleAttributes);
    return visibleAttributes;
  }

  private processControllingAttribute(
    controllingAttribute: ConversationAttributeDescriptor,
    visibleAttributes: ConversationAttributeDescriptor[],
  ): void {
    let dependentAttributes = this.getDependentAttributes(controllingAttribute);
    let controllerValue = this.attributeValues[controllingAttribute.id]?.value as string;

    dependentAttributes.forEach((dependentAttribute) => {
      this.processDependentAttribute(dependentAttribute, controllerValue, visibleAttributes);

      if (dependentAttribute.conditions?.length > 0) {
        this.processControllingAttribute(dependentAttribute, visibleAttributes);
      }
    });
  }

  private getDependentAttributes(
    attribute: ConversationAttributeDescriptor,
  ): ConversationAttributeDescriptor[] {
    return this.attributeDescriptors.filter((descriptor) =>
      descriptor.conditions?.some(
        (condition) => condition.controllingDescriptorId === attribute.id,
      ),
    );
  }

  private processDependentAttribute(
    dependentAttribute: ConversationAttributeDescriptor,
    controllerValue: string,
    visibleAttributes: ConversationAttributeDescriptor[],
  ): void {
    let matchesController = this.matchesController(dependentAttribute, controllerValue);
    let matchesAnotherController = this.matchesAnotherController(
      dependentAttribute,
      visibleAttributes,
    );

    if (matchesController || matchesAnotherController) {
      this.addToVisibleAttributes(dependentAttribute, visibleAttributes);
    } else {
      this.removeFromVisibleAttributes(dependentAttribute, visibleAttributes);
    }
  }

  private updateConditionalOptions(attribute: ConversationAttributeDescriptor): void {
    if (!this.hasListOptions(attribute)) {
      return;
    }
    if (this.hasNoConditions(attribute)) {
      this.listOptions[attribute.id] = attribute.listOptions!.map((option) => option.id);
      return;
    }

    this.listOptions[attribute.id] = this.conditionalOptionIdsFor(attribute);

    // in case attribute depends on multiple controllers, there might be a situation
    // where already selected value is not available anymore in the listOptions
    // in this case we should unset the attribute and let the user pick a new value
    if (
      this.attributeValues[attribute.id] &&
      this.attributeValues[attribute.id].value &&
      !this.listOptions[attribute.id].includes(this.attributeValues[attribute.id].value as string)
    ) {
      this.unsetAttribute(attribute);
    }
  }

  private conditionalOptionIdsFor(descriptor: ConversationAttributeDescriptor): string[] {
    let result: string[] = [];
    for (let condition of descriptor.conditions) {
      if (this.isConditionMet(condition)) {
        if (!condition.descriptorListOptionIds || condition.descriptorListOptionIds.length === 0) {
          return descriptor.listOptions!.map((option) => option.id);
        }
        result.push(...condition.descriptorListOptionIds);
      }
    }

    // attributes set by automations do not respect conditionality and
    // should be included in the list of accessible options if they are set
    if (result.length === 0 && this.attributeValues[descriptor.id]?.value) {
      result.push(this.attributeValues[descriptor.id]?.value as string);
    }

    return result;
  }

  private matchesController(
    dependentAttribute: ConversationAttributeDescriptor,
    controllerValue: string,
  ): boolean {
    return (
      dependentAttribute.conditions?.some(
        (condition) =>
          condition.controllingDescriptorId === dependentAttribute.id &&
          condition.controllingListOptionId === controllerValue,
      ) || false
    );
  }

  private matchesAnotherController(
    dependentAttribute: ConversationAttributeDescriptor,
    visibleAttributes: ConversationAttributeDescriptor[],
  ): boolean {
    return (
      dependentAttribute.conditions?.some((condition) =>
        visibleAttributes.some(
          (visibleAttr) =>
            visibleAttr.id === condition.controllingDescriptorId &&
            this.attributeValues[visibleAttr.id]?.value === condition.controllingListOptionId,
        ),
      ) || false
    );
  }

  private addToVisibleAttributes(
    attribute: ConversationAttributeDescriptor,
    visibleAttributes: ConversationAttributeDescriptor[],
  ): void {
    if (!visibleAttributes.some((attr) => attr.id === attribute.id)) {
      visibleAttributes.push(attribute);
    }
  }

  private removeFromVisibleAttributes(
    attribute: ConversationAttributeDescriptor,
    visibleAttributes: ConversationAttributeDescriptor[],
  ): void {
    let index = visibleAttributes.findIndex((attr) => attr.id === attribute.id);
    if (index !== -1) {
      visibleAttributes.splice(index, 1);
      this.unsetAttribute(attribute);
    }
  }

  private unsetAttribute(attribute: ConversationAttributeDescriptor): void {
    let attr = this.attributeValues[attribute.id];
    if (attr?.value) {
      attr.value = undefined;
      this.updateAttribute?.(attr);
    }
  }

  private getAttributeDescriptor(
    attribute: ConversationAttributeSummary,
  ): ConversationAttributeDescriptor {
    return this.attributeDescriptors.find(
      (descriptor) => descriptor.id === attribute.descriptor.id,
    ) as ConversationAttributeDescriptor;
  }
}

declare module '@ember/service' {
  interface Registry {
    conditionalAttributesService: ConditionalAttributesService;
    'conditional-attributes-service': ConditionalAttributesService;
  }
}
